@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .underline-hover{
        @apply transition-all duration-500 before:content-[''] before:absolute before:-bottom-1 before:left-0 before:w-0 before:h-2.5 before:transition-all before:duration-300 before:bg-accent-bar hover:before:w-full
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
}